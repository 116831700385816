import * as React from "react";
import { Helmet } from "react-helmet";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

import styles from "./index.module.css";

import Hero from "../../../../components/ApartmentHero";
import Icon from "../../../../components/Icon";
import Footer from "../../../../components/Footer";

import photo1 from "../../../../../img/apartments/classic_single/1.jpg";
import photo2 from "../../../../../img/apartments/classic_single/2.jpg";
import photo3 from "../../../../../img/apartments/classic_single/3.jpg";
import photo4 from "../../../../../img/apartments/classic_single/4.jpg";
import photo5 from "../../../../../img/apartments/classic_single/5.jpg";
import photo6 from "../../../../../img/apartments/classic_single/6.jpg";
import photo7 from "../../../../../img/apartments/classic_single/7.jpg";
import photo8 from "../../../../../img/apartments/classic_single/8.jpg";
import photo9 from "../../../../../img/apartments/classic_single/9.jpg";
import photo10 from "../../../../../img/apartments/classic_single/10.jpg";
import photo11 from "../../../../../img/apartments/classic_single/10.jpg";

export const ClassicSingle = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Classic Single room</title>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Cinzel:wght@700&family=Coda&family=IBM+Plex+Serif:wght@300&display=swap" />
        <script data-react-helmet="true" src="https://kit.fontawesome.com/7488f5bdb2.js" crossorigin="anonymous"></script>
      </Helmet>
      <Hero title="Classic Single Room" photo={photo1} />
      <div className={styles.container}>
        <p className={styles.description}>Classic single Room with an area of 16 sq. meters and one single bed (140 cm) provided with a premium class memory foam mattress, ensuring comfortable rest. Stylishly finished and equipped. All rooms are air-conditioned and non-smoking. At the disposal of our guests are bottled mineral water, a coffee machine with a selection of delicious coffee pods, an electric kettle with tea bags.</p>
        <div className={styles.grid}>
          <Icon apartment icon="fa-bed" text="1 enlarged single bed (140 cm)" />
          <Icon apartment icon="fa-chair" text="Desk with chair" />
          <Icon apartment icon="fa-coffee" text="Kettle, teas, Nespresso coffee machine" />
          <Icon apartment icon="fa-lock" text={`15.6" laptop size safe`} />
          <Icon apartment icon="fa-tint" text="Sparkling and still mineral water" />
          <Icon apartment icon="fa-tv" text="TV with cable channels" />
          <Icon apartment icon="fa-wifi" text="Free WI-FI" />
          <Icon apartment icon="fa-shower" text="2 sets of towels, shower gel, liquid soap & hair dryer" />
        </div>
        <Carousel showArrows={true}>
                <div>
                  <img src={photo1} />
                </div>
                <div>
                  <img src={photo2} />
                </div>
                <div>
                  <img src={photo3} />
                </div>
                <div>
                  <img src={photo4} />
                </div>
                <div>
                  <img src={photo5} />
                </div>
                <div>
                  <img src={photo6} />
                </div>
                <div>
                  <img src={photo7} />
                </div>
                <div>
                  <img src={photo8} />
                </div>
                <div>
                  <img src={photo9} />
                </div>
                <div>
                  <img src={photo10} />
                </div>
                <div>
                  <img src={photo11} />
                </div>
        </Carousel>
        <hr />
        <p className={styles.regulations}>The rooms are cleaned on the Guest's request (No later than to 1:00 PM). Guests can borrow an iron and ironing board.</p>
        <hr />
        <p className={styles.regulations}>For the safety of our guests, they have smoke detectors. The only designated place for smoking is the patio located on the ground floor opposite the front office.</p>
        <hr />
      </div>
      <Footer />
    </>
  );
};

export default ClassicSingle;
